.form {

  --font-color: var(--color-blue-100);
  --border-color: #92A3BA;
  --background-color: #92A3BA;
  --form-secondary: #9d9d9d;

  display: block;
  width: 100%;
  max-width: 640px;
  margin: 2rem 0;

  .mandatory {
    &:after {
      content: '*';
      color: var(--color-red-100);
    }
  }

  label {
    display: block;
    color: var(--font-color);
    font-weight: bold;

    a {
      color: inherit;
      text-decoration: underline;
    }

    & + label {
      margin-top: $spacing-l;
    }
  }

  #error {
    //@include form;
    color: #ff4444;
    margin-bottom: 2rem;
  }

  input:not([type]),
  input[type=text],
  input[type=email],
  input[type=number],
  input[type=password],
  input[type=tel],
  input[type=url],
  textarea {
    display: block;
    width: 100%;
    padding: .75rem 1rem;
    margin: .5rem auto;
    background-color: transparent;
    color: var(--font-color);
    border-radius: 4px;
    border: 1px solid var(--border-color);

    &:focus {
      border-color: var(--font-color);
      outline: none;
    }

  }

  textarea {
    resize: none;
  }

  input[type=submit],
  input[type=button],
  input[type=reset],
  button[type=submit] {
    @extend .btn--primary--red--dark;
    cursor: pointer;
    margin-top: $spacing-l;
  }

  &__checkmark {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      left: -9999px;

      &[type=radio] ~ span:before {
        border-radius: 50%;
      }

      &:checked ~ span {

      }

      &:checked ~ span:before {
        display: block;
        background-color: var(--font-color);
      }

    }

    span {
      position: relative;
      padding-left: 36px;
      line-height: 24px;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        height: 24px;
        width: 24px;
        border: 1px solid var(--border-color);
        border-radius: 4px;
        box-shadow: inset 0 0 0 5px var(--color-beige-10);
      }

    }

    &:hover input ~ span:before {
      border-color: var(--border-color);
    }

    &:hover input:checked ~ span:before {
      border-color: var(--border-color);
    }

  }

  &__group {
    padding: 1.5rem 0;
  }

  &__label {
    margin-bottom: 1.5rem;
  }

  &__stack {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;

    @include respond-to("s") {
      flex-wrap: nowrap;
    }

    & > * {
      width: 100%;
      margin: 0 1rem;
    }
  }

  &__response {
    @include body;
    margin-top: $spacing-xxl;
    color: var(--color-red-100);
  }

  // Honeypot
  &__feature {
    display:none !important;
  }

}

::placeholder {line-height: normal; color: var(--form-secondary)}
::-webkit-input-placeholder { line-height: normal; }

#message,#message p{
  width: 100%;
  max-width: 640px;
  margin: 0 auto 2rem;
}


$mc-color-error: #ff4444;
$mc-color-success: initial;

#mc_embed_signup {

  .mc-field-group {
    border: none;
    margin: 1.5rem 0;
    padding: 0;
    @include respond-to("m") {
      margin: 2rem 0;
    }
  }

  .asterisk {
    color: inherit;
  }

  #mce-error-response {
    display: none;
  }

  #mce-success-response {
    color: $mc-color-success;
    display: none;
    margin: 1.5rem 0 2rem;
    @include respond-to("m") {
      margin: 2.25rem 0 3rem;
    }
    @include respond-to("l") {
      margin: 3rem 0 4rem;
    }
  }

  #mc-embedded-subscribe-form input[type=checkbox] {
    display: inline;
    width: auto;
    margin-right: 10px;
  }

  #mc-embedded-subscribe-form div.mce_inline_error {
    background: transparent;
    margin: 0.75rem 0;
    padding: 0;
    color: $mc-color-error;
  }

  #mc-embedded-subscribe-form input.mce_inline_error {
    border-color: $mc-color-error;
  }

  #mc-embedded-subscribe {
    clear: both;
    width: auto;
  }

  .hideOnInit {
    display: none;
  }

  .gdpr-mergeRow span {
    // @include meta;
  }

}
