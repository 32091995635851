@import 'node_modules/font-face-generator/mixin';

@include font-face(
  $fonts: (
    'Raleway': (
      500: (
        normal: 'raleway-v22-latin-500',
      ),
      600: (
        normal: 'raleway-v22-latin-600',
      ),
      700: (
        normal: 'raleway-v22-latin-700',
      )
    )
  ),
  $types: 'woff2' 'woff' 'ttf',
  $path: '../fonts'
);

@include font-face(
  $fonts: (
    'Roustel': (
      400: (
        normal: 'roustel',
      )
    )
  ),
  $types: 'ttf',
  $path: '../fonts'
);
