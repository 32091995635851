.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-m;

  &__actions {
    display: flex;
    align-items: center;

    .language + div {
      margin-left: $spacing-xl;
    }
  }

  @include respond-to('m') {
    padding: $spacing-xl $spacing-xxl;
  }

}

.header_blank {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $spacing-m;

  &__actions {
    display: flex;
    align-items: center;

    .language + div {
      margin-left: $spacing-xl;
    }
  }

  @include respond-to('m') {
    padding: $spacing-xl $spacing-xxl;
  }

}

.page {
/*  flex-basis: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;*/
  // margin-block-start: 49px;
  &__image {
    margin-block-start: 20px;
    max-width: 70vw;
    @include respond-to('xs') {
      max-width: 440px;
    }
  }
  &__title,
  &__content,
  &__contact {
    font-family: "Raleway", sans-serif;
    font-style: normal;
    text-align: center;
    color: #183F76;
    box-sizing: border-box;
  }

  &__title {
    @include large-title;
    max-width: 20ch;
    margin-block-start: 24px;
    margin-block-end: 16px;
    @include respond-to('xs') {
      margin-block-start: 53px;
      margin-block-end: 14px;
    }

  }
  &__content{
    margin-block-start: 0;
    margin-block-end: 1.63em;
    font-size: 20px;
    line-height: 150%;
    @include respond-to('xs') {
      font-size: 30px;
      line-height: 163%;
      text-align: center;
      letter-spacing: 0.01em;
    }
  }
  &__contact {
    @include body;
    color: #0D3B66;
    margin-block-end: 1.23em;
    @include respond-to('xs') {
      margin-block-end: 1.63em;
    }
  }
}
.footer {
  background-color: var(--color-blue-100);
  color: var(--color-beige-10);
  @include footer;
  @include font-regular;
  padding: $spacing-xl $spacing-m;

  &__row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > * + * {
      margin-top: $spacing-xl;
    }

    & + .footer__row {
      margin-top: $spacing-xxl;
    }
  }

  address {
    font-style: normal;
  }

  h4 {
    @include font-bold;
  }

  a {
    color: inherit;

    &:hover, &:focus, &:active {
      text-decoration: underline;
    }
  }

  nav {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0;
        padding: 0;
      }
    }
  }

  @include respond-to('s') {
    &__row {
      flex-direction: row;
      justify-content: space-between;
      flex-basis: 50%;

      & > * + * {
        margin-top: 0;
        margin-left: $spacing-xl;
      }

      & + & {
        align-items: center;
      }
    }
  }

  @include respond-to('m') {
    padding: $spacing-xl $spacing-xxl;
  }
}
