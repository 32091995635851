/* Typography */

$sans: 'Raleway', sans-serif;
$script: 'Roustel', sans-serif;
$serif:  serif;
$mono: Menlo, Courier, monospace;

@mixin large-title {
  font-size: 28px;
  line-height: 128%;
  letter-spacing: -0.015em;

  @include respond-to('l') {
    font-size: 60px;
  }

}

@mixin title-1 {
  font-size: 32px;
  line-height: 135%;
  letter-spacing: -0.01em;

  @include respond-to('m') {
    font-size: 52px;
  }

}

@mixin title-2 {
  font-size: 24px;
  line-height: 135%;
  letter-spacing: -0.0075em;

  @include respond-to('m') {
    font-size: 30px;
  }
}

@mixin body {
  font-size: 16px;
  line-height: 170%;
  letter-spacing: 0.01em;

  @include respond-to('m') {
    font-size: 18px;
  }
}

@mixin kpi {
  font-size: 54px;
  line-height: 1;
  letter-spacing: -0.025em;

  @include respond-to('m') {
    font-size: 72px;
  }
}

@mixin kpi-headline {
  font-size: 18px;
  line-height: 120%;
  letter-spacing: 0.02em;

  @include respond-to('m') {
    font-size: 22px;
  }
}

@mixin kpi-small {
  font-size: 28px;
  line-height: 1;
  letter-spacing: -0.01em;
}

@mixin quote-source {
  font-family: $script;
  font-size: 28px;
  line-height: 120%;

  @include respond-to('m') {
    font-size: 40px;
  }
}

@mixin quote-source-small {
  font-family: $script;
  font-size: 21px;
  line-height: 120%;

  @include respond-to('s') {
    font-size: 28px;
  }

  @include respond-to('m') {
    font-size: 40px;
  }
}

@mixin footer {
  font-size: 16px;
  line-height: 160%;

  @include respond-to('m') {
    font-size: 15px;
  }
}

@mixin footer-large {
  font-size: 24px;
  line-height: 120%;
}

@mixin copyright {
  font-size: 11px;
  line-height: 160%;
}

@mixin font-light {
  font-weight: var(--font-light);
}

@mixin font-regular {
  font-weight: var(--font-regular);
}

@mixin font-bold {
  font-weight: var(--font-bold);
}

@mixin font-upper {
  text-transform: uppercase;
  letter-spacing: .01em;
}

