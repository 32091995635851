/* Mixins */
@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin font($font) {
  font-family: $font;
  -webkit-text-size-adjust: none;
  /*auto-zoom auf iphone ausschalten*/
  -ms-text-size-adjust: none;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  word-wrap: break-word;
}

@mixin bc-transition($time) {
  -webkit-transition: all $time linear;
  -moz-transition: all $time linear;
  -o-transition: all $time linear;
  -ms-transition: all $time linear;
  transition: all $time linear;
}

@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin respond-to($breakpoint) {
  // Retrieves the value from the key
  $value: map-get($breakpoints, $breakpoint);
  // If the key exists in the map
  @if $value != null {
    // Prints a media query based on the value
    @media (min-width: $value) {
      @content;
    }
  }
  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. " + "Please make sure it is defined in `$breakpoints` map.";
  }
}
