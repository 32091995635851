/* Variables */

/* Colors */
$black: #000;
$white: #fff;

$gray-1: #707070;
$gray-2: #c0c0c0;
$gray-3: #f0f0f0;

/* Element-Colors */
$c_typo_primary: rgba(0, 0, 0, 1);
$c_typo_secondary: rgba(30, 30, 30, 1);

$c_bg_primary: $white;
$c_bg_secondary: $gray-3;

$c_typo_invers: $white;
$c_bg_invers: $black;
$c_bg_hero: $black;

/* Breakpoints */
$breakpoints: (
  'xs': 480px,
  's': 640px,
  'm': 960px,
  'l': 1280px,
  'xl': 1680px
);
/* Z-Indexes */
$z-indexes: (
  'modal': 5000,
  'dropdown': 4000,
  'default': 1,
  'below': -1
);

/* Spacing */
$spacing: 16px;
$spacing-xs: 4px;
$spacing-s: 8px;
$spacing-m: 16px;
$spacing-l: 24px;
$spacing-xl: 32px;
$spacing-xxl: 48px;
$spacing-xxxl: 80px;

:root {
  --color-main: rgba(253, 115, 36, 1);
  --color-secondary: rgba(230, 68, 26, 1);
  --color-typography: rgba(30, 30, 30, 1);
  --color-typography-alt: rgba(30, 30, 30, 1);
  --color-white: rgba(255, 255, 255, 1);
  --color-black: rgba(0, 0, 0, 1);
  --font-rendering: subpixel-antialiased;
  --font-light: 500;
  --font-regular: 600;
  --font-bold: 700;
  --color-gray: #4F4944;
  --color-white-100: #FFFFFF;
  --color-bg-primary: #F9DBC0;
  --color-beige-100: #F9DBC0;
  --color-beige-10: #FFF9F4;
  --color-blue-100: #183F76;
  --color-blue-alt-100: #3A5C8A;
  --color-blue-10: rgba(24, 63, 118, 0.1);
  --color-red-100: #F0756A;
  --color-red-25: rgba(240, 117, 106, 0.25);
  --color-gold-100: #E1BF8C;
}
