/* Base */

*,
*:after,
*:before {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  vertical-align: baseline;
  font-family: $sans;
  @include font-regular;
  font-style: normal;
}

html {
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-rendering: optimizeLegibility;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  position: relative;
  background-color: var(--color-beige-10);
  color: var(--color-blue-100);
  display: flex;
  flex-direction: column;
  margin: 0;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

a {
  text-decoration: none;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

ul.checked {
  list-style: none;
  margin-left: 2em;
  padding: 0;
  text-indent: -2em;

  li {
    padding: .25rem 0;

    &:before {
      content: url("data:image/svg+xml,%3Csvg width='23' height='18' viewBox='0 0 23 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M22.3872 2.98489L19.4023 0L7.46243 11.9399L2.98489 7.46238L0 10.4473L7.46222 17.9095L7.46259 17.9091L7.4628 17.9093L22.3872 2.98489Z' fill='%23F0756A'/%3E%3C/svg%3E");
      margin-right: .7em;
    }
  }
}

.large-title {
  @include large-title;
  @include font-bold;
  margin-bottom: .5em;
}

.title-1 {
  @include title-1;
  @include font-bold;
  margin-bottom: .65em;
}

.title-2 {
  @include title-2;
  @include font-bold;
  margin-bottom: .65em;
}

.overline {
  @include body;
  @include font-bold;
  color: var(--color-red-100);
  display: inline-block;
  margin-bottom: .65em;
}

.body-text {
  @include body;
  // max-width: 64ch;
}

.copyright {
  @include copyright;
  @include font-bold;
}

.bg {
  &--beige {
    background-color: var(--color-beige-100);
  }
  &--blue {
    background-color: var(--color-blue-100);
    color: var(--color-beige-10);
  }
  &--red {
    background-color: var(--color-red-100);
    color: var(--color-white);

    .overline {
      color: var(--color-white);
    }
    .title-1 {
      color: var(--color-blue-100);
    }
  }
}

section {
  padding: $spacing-xxl 0;
  img, picture, video, canvas {
    width: 100%;
    height: auto;
  }

  @include respond-to('m') {
    padding: $spacing-xxxl 0;
  }
}

.container {
  width: 100%;
  max-width: 1336px;
  margin: 0 auto;
}

.container--grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 0 $spacing-m;
}

.nav {
  &--main {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;

      li {
        margin: 0;
        padding: 0;

        a {
          @include body;
          @include font-bold;
          color: var(--color-blue-100);
          padding: .5em 1.5em;

          &:hover, &:active, &:focus {
            color: var(--color-blue-alt-100);
          }
        }
      }
    }
  }
}

.hero {
  grid-column: 1 / 13;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__media {
    margin-bottom: $spacing-m;
    overflow: hidden;
  }

  &__message {
    font-family: $script;
    @include quote-source-small;
    max-width: 17ch;
    margin-left: auto;
    margin-top: $spacing-l;
    transform: rotate(13deg);
  }

  &__content {

  }

  @include respond-to('m') {
    flex-direction: row;

    &__media {
      flex-basis: 50%;
      order: 1;
      margin-bottom: 0;
      padding: $spacing-xl;
    }

    &__content {
      flex-basis: 50%;
      padding: $spacing-m;
    }
  }
}


.hero__badges {

  img, svg {
    height: 50px;
    width: auto;
    display: block;
  }
}

.campaign {
  &__scribble {
    font-family: $script;
    @include quote-source-small;
    margin-left: $spacing-l;
    margin-top: $spacing-m;
    transform: rotate(13deg);
    color: var(--color-blue-100);
  }
}

.partner {
  grid-column: 1 / 13;

  &__headline {
    text-align: center;
    max-width: 30ch;
    margin: 0 auto;
  }

  &__list {
    text-align: center;
    color: var(--color-gray);
  }

  .flex-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: $spacing-xxl -$spacing-m 0;

    .item {
      padding: $spacing-m;

      img {
        max-height: 70px;
        width: auto;
      }

      .placeholder {
        display: block;
        min-width: 120px;
        height: 50px;
        background: var(--color-beige-100);
      }
    }
  }

  @include respond-to('s') {
    grid-column: 2 / 12;
  }
}

.text {
  grid-column: 1 / 13;

  &__headline {
    text-align: center;
    max-width: 30ch;
    margin: 0 auto;
  }

  &__text {
    text-align: center;
    margin-top: $spacing-l;
    color: var(--color-gray);
  }

  @include respond-to('s') {
    grid-column: 3 / 11;
  }

  @include respond-to('m') {
    &__text {
      margin-top: $spacing-xl;
    }
  }
}

.media {
  grid-column: 1 / 13;

  &__headline {
    color: var(--color-beige-100);
    text-align: center;
  }

  &__medium {
    position: relative;
    padding-bottom: 56.25%;
    margin-top: $spacing-xl;
    height: 0;
    overflow: hidden;

    & iframe,
    & object,
    & embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }
  }

  @include respond-to('s') {
    grid-column: 3 / 11;
  }

  @include respond-to('m') {
    &__medium {
      margin-top: $spacing-xxl;
    }
  }
}

.testimonials {
  grid-column: 1 / 13;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @include respond-to('s') {
    grid-column: 2 / 12;
  }
}

.swiper {
  width: 100%;

  &-slide {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }

  &-button-next,
  &-button-prev {
    display: none;
    color: var(--color-red-100);

    &:after {
      font-size: 24px;
    }
  }

  &-pagination-bullet {
    background: transparent;
    border-width: 2px;
    border-style: solid;
    border-color: var(--color-red-100);
    opacity: 1;
    width: 10px;
    height: 10px;
    &-active {
      background: var(--color-red-100);
    }
  }

  @include respond-to('m') {
    &-button-next,
    &-button-prev {
      display: flex;
    }
  }
}

.quote {
  grid-column: 1 / 13;

  blockquote {
    text-align: center;
    @include title-1;
    @include font-bold;
    margin: 0;

    &:before {
      display: block;
      content: "”";
      font-size: 124px;
      line-height: 0.5;
    }

    footer {
      @include quote-source;
      margin-top: $spacing-xxl;
    }
  }

  @include respond-to('s') {
    grid-column: 2 / 12;
  }

  @include respond-to('m') {
    blockquote {
      margin: $spacing-xl;

      &:before {
        display: inline;
      }
    }
  }
}

.testimonial {
  text-align: center;
  @include title-2;
  @include font-bold;
  margin: $spacing-xl auto;
  max-width: 48ch;

  footer {
    display: flex;
    align-items: center;
    justify-content: center;
    @include body;
    @include font-regular;
    max-width: 60ch;
    margin-left: auto;
    margin-right: auto;
    margin-top: $spacing-xxl;
    margin-bottom: $spacing-xxxl;

    &:before {
      content: "";
      display: block;
      width: 24px;
      height: 2px;
      margin-right: $spacing-m;
      background-color: var(--color-red-100);
    }
  }
}

.kpis {
  grid-column: 1 / 13;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  &__headline {
    margin-bottom: $spacing-xxl;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .kpi {
    display: flex;
    flex-basis: 280px;
    padding: $spacing-l;

    &__icon {
      width: 100%;
      height: auto;
      max-width: 96px;
      margin-right: $spacing-m;
    }

    &__headline {
      @include kpi-headline;
    }

    &__value {
      @include kpi;

      small {
        @include kpi-small;
      }
    }

    &__label {
      @include body;
      margin-top: $spacing-m;
    }
  }

  &--centered {
    @extend .kpis;
    align-items: center;

    .kpis__container {
      align-items: center;
    }

    .kpi {
      text-align: center;
      flex-direction: column;
      align-items: center;
    }
  }

  @include respond-to("s") {
    text-align: center;
    align-items: center;
    grid-column: 2 / 12;

    &__container {
      flex-direction: row;
    }

    .kpi {
      flex-basis: 100%;
      flex-shrink: 0.5;
      flex-direction: column;
      text-align: left;

      &__icon {
        margin-bottom: $spacing-m;
        margin-right: initial;
      }
    }

    &--centered {
      .kpi {
        text-align: center;
      }
    }
  }
}

.teaser {
  grid-column: 1 / 13;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__media {
    margin-bottom: $spacing-xxl;

    picture {
      border-radius: 12px;
      overflow: hidden;
    }
    &--2x {
      picture {
        max-width: 250px;
        margin: auto;
      }
    }
    &--group {
      picture {
        &:last-of-type {
          display: none;
        }
      }
    }
  }

  &__content {

  }

  @include respond-to('s') {
    grid-column: 2 / 12;
  }

  @include respond-to('m') {
    flex-direction: row;

    &__media {
      flex-basis: 50%;
      margin-bottom: 0;
      padding: $spacing-m;
      padding-right: $spacing-xxl;

      &--group {
        picture {
          &:first-of-type {
            width: 70%;
          }
          &:last-of-type {
            display: block;
            margin-top: $spacing-l;
          }
        }
      }
    }

    &__content {
      flex-basis: 50%;
      padding: $spacing-m;
    }

    &--right {
      .teaser__media {
        order: 1;
        padding-right: $spacing-m;
        padding-left: $spacing-xxl;
      }
    }
  }
}

/*
  Actions
*/
.actions {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: $spacing-xxl;

  & > * + * {
    margin-top: 1rem;
  }

  @include respond-to('s') {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;

    & > * + * {
      margin-top: 0;
      margin-left: 1rem;
    }
  }

  &--full {
    @include respond-to('s') {
      flex-direction: column;
      align-items: stretch;
      flex-wrap: nowrap;
      justify-content: flex-start;

      & > * + * {
        margin-top: 1rem;
        margin-left: 0;
      }
    }
  }
}

/*
  Button
*/

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 27px;
  padding: 1em 1.75em;
  border: none;
  transition: color, background-color .2s;

  .icon {
    fill: currentColor;

    path {
      fill: currentColor;
    }

    &--left {
      @extend .icon;
      margin-right: 2.5em;
    }

    &--right {
      @extend .icon;
      margin-left: 2.5em;
    }
  }

  &--primary {
    @extend .btn;
    background-color: var(--color-blue-100);
    color: var(--color-beige-10);

    &:hover, &:focus, &:active {
      background-color: var(--color-blue-alt-100);
    }
  }
  &--primary--dark {
    @extend .btn;
    background-color: var(--color-blue-100);
    color: var(--color-beige-100);

    &:hover, &:focus, &:active {
      background-color: var(--color-blue-alt-100);
    }
  }
  &--primary--gold {
    @extend .btn;
    background-color: var(--color-gold-100);
    color: var(--color-blue-100);

    &:hover, &:focus, &:active {
      background: linear-gradient(0deg, var(--color-blue-10), var(--color-blue-10)), var(--color-gold-100);
    }
  }
  &--primary--red {
    @extend .btn;
    background-color: var(--color-red-100);
    color: var(--color-beige-10);

    &:hover, &:focus, &:active {
      background: linear-gradient(0deg, var(--color-blue-10), var(--color-blue-10)), var(--color-red-100);
    }
  }
  &--primary--red--dark {
    @extend .btn;
    background-color: var(--color-red-100);
    color: var(--color-blue-100);

    &:hover, &:focus, &:active {
      background: linear-gradient(0deg, var(--color-blue-10), var(--color-blue-10)), var(--color-red-100);
    }
  }
  &--primary--white {
    @extend .btn;
    background-color: var(--color-beige-10);
    color: var(--color-blue-100);

    &:hover, &:focus, &:active {
      background: linear-gradient(0deg, var(--color-red-25), var(--color-red-25)), var(--color-beige-10);
    }
  }
  &--secondary {
    @extend .btn;
    border-width: 2px;
    border-style: solid;
    transition: color, background-color, border-color .2s;
    border-color: var(--color-blue-100);
    background-color: transparent;
    color: var(--color-blue-100);

    &:hover, &:focus, &:active {
      background: linear-gradient(0deg, var(--color-blue-10), var(--color-blue-10));
    }

    @include respond-to('s') {
      justify-content: center;

      .icon--right {
        display: none;
      }
    }
  }
  &--secondary--icon {
    @extend .btn;
    border-width: 2px;
    border-style: solid;
    transition: color, background-color, border-color .2s;
    border-color: var(--color-blue-100);
    background-color: transparent;
    color: var(--color-blue-100);

    &:hover, &:focus, &:active {
      background: linear-gradient(0deg, var(--color-blue-10), var(--color-blue-10));
    }
  }
}

.until {

  &--tablet {
    display: none;
    @include respond-to('s') {
      display: initial;
    }
  }

  &--desktop {
    display: none;

    @include respond-to('m') {
      display: initial;
    }
  }

  &--desktop-xl {
    display: none;

    @include respond-to('l') {
      display: initial;
    }
  }
}

.only {

  &--mobile {
    @include respond-to('m') {
      display: none;
    }
  }

  &--tablet {
    @include respond-to('s') {
      display: none;
    }
  }

  &--desktop {
    @include respond-to('m') {
      display: none;
    }
  }
}

.arrow {
  &--top {
    position: relative;
    &:after {
      content: url("data:image/svg+xml,%3Csvg viewBox='0 0 30 60' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27.717 47.3211C27.2265 51.203 25.8114 54.8855 23.4735 58.3745C23.3648 60.2489 24.5008 59.8636 25.0824 59.4367C27.5847 55.6815 29.1017 51.7261 29.6328 47.5692C30.163 43.4144 29.7905 39.1188 28.5136 34.6891C26.9075 28.7718 24.0362 23.2913 19.8992 18.2471C15.7638 13.2032 10.9684 9.33545 5.51407 6.64474C9.80066 6.22278 13.4308 4.8471 16.4005 2.51364C16.8962 2.10904 16.9374 1.66046 16.5335 1.16623C16.1288 0.674115 15.68 0.630952 15.1866 1.03375C13.3412 2.49081 11.4379 3.46223 9.47538 3.94616C7.51357 4.43153 4.96664 4.76331 1.83229 4.94785L1.5405 4.98173C1.04283 4.94449 0.698633 5.15924 0.510236 5.62417C0.435803 5.83198 0.432005 6.00493 0.497517 6.14467C0.393333 6.50329 0.457477 6.81517 0.686204 7.08281C1.27835 7.89865 1.88369 8.83581 2.50711 9.89267C3.12633 10.9526 3.62814 11.8284 4.00767 12.5219C4.38719 13.2153 4.9231 14.2722 5.61271 15.6912C6.30257 17.1123 6.80301 18.0728 7.11066 18.5782C7.42893 19.1794 7.86437 19.3249 8.41906 19.0108C8.97287 18.6977 9.09761 18.2876 8.78852 17.7828C5.93176 12.1134 4.17413 8.80373 3.51623 7.84614C9.09856 10.3746 13.9515 14.1115 18.0756 19.0581C22.2015 24.0071 25.0831 29.364 26.7184 35.13C27.8732 39.3783 28.2065 43.4429 27.717 47.3211Z' fill='%23183F76'/%3E%3C/svg%3E");
      position: absolute;
      top: -3rem;
      left: 32%;
      width: 20px;
    }
  }

  @include respond-to('m') {
    &--top {
      &:after {
        top: -5rem;
        width: 30px;
      }
    }
  }
}

.underline {
  position: relative;

  &--bottom {
  @extend .underline;

    &:after {
      content: url("data:image/svg+xml,%3Csvg viewBox='0 0 340 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 14C71.6475 4.64691 201.168 -4.18801 339 4.64641' stroke='%23F0756A' stroke-width='2'/%3E%3C/svg%3E%0A");
      position: absolute;
      bottom: -1rem;
      left: 0;
      width: 100%;
    }
  }

  @include respond-to('m') {
    &--bottom {
      &:after {
        bottom: -1.2rem;
      }
    }
  }

  @include respond-to('l') {
    &--bottom {
      &:after {
        bottom: -1.8rem;
      }
    }
  }
}

.outline {
  position: relative;
  &:after {
    position: absolute;
    top: -.45rem;
    left: -1rem;
    right: -1rem;
    bottom: 0;
    content: url("data:image/svg+xml,%3Csvg viewBox='0 0 387 98' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M367.606 30.1938C324.476 -5.46971 325.11 1.56252 187.474 1.56252C49.8386 1.56252 1 25.2122 1 55.2298C1 85.2473 125.963 97 223.381 97C283.878 97 370.778 97 386 37.226' stroke='%23F0756A' stroke-width='2'/%3E%3C/svg%3E%0A");
  }
}

/* Language Switcher */
.language {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;

  &__item {
    display: flex;
    align-items: center;
    &:after {
      content: "";
      width: 2px;
      height: 16px;
      background-color: var(--color-blue-10);
      margin-left: .5rem;
    }
    &:last-of-type {
      &:after {
        content: none;
      }
    }

    & + .language__item {
      margin-left: .5rem;
    }
  }

  &__link {
    display: block;
    padding: .5rem 0;
    @include font-regular;
    @include font-upper;
    color: inherit;

    &:hover,
    &.active {
      @include font-bold;
      text-decoration: underline;
    }
  }
}

.contact {
  &__link {
    @include footer-large;
  }
}

.markdown {
  grid-column: 1 / 13;
  padding: $spacing-xxl 0;

  h1 {
    @include title-1;
  }

  h2 {
    @include title-2;
  }

  h3 {
    @include body;
    @include font-bold;
  }

  p {
    @include body;

    & + p {
      margin-top: 1em;
    }

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  * + h1,
  * + h2,
  * + h3 {
    margin-top: $spacing-xxl;
  }

  @include respond-to('s') {
    grid-column: 2 / 12;
  }
}

/* Social Media */
.social-media {
  list-style-type: none;
  margin: 0 2em 0 0;
  padding: 0;
  display: none;
  flex-wrap: nowrap;

  @include respond-to('xs') {
    display: flex;
  }
  
  @include respond-to('m') {
    margin: 0 4em 0 0; 
  }

  li {
    margin: 0 1.5em 0 0;
  }

  a {
    color: var(--color-blue-100)
  }

  a:hover { 
    color: var(--color-blue-alt-100)
  }  
}
 
/* Linktree */
.linktree {
  grid-column: 1 / 13;
  padding: 16px; //$spacing-xxl 0
  align-items: center;
  display: flex;
  flex-direction: column;
  
  p {
    @include body;
    text-align: center;
    margin: 24px 0 0 0;
  }

  .btn--primary--dark {
    width: 80%;
    margin: 8px 0;
    justify-content: center;

    @include respond-to('s') {
      width: 50%;
    }
  }

  @include respond-to('s') {
    grid-column: 2 / 12;
  }
}